import { Routes } from '@angular/router';
import { samlLoginGuard } from '../../core/guards/saml-login.guard';
import { loggedOutGuard } from '../../core/guards/logged-out-guard';
import { loggedInGuard } from '../../core/guards/logged-in.guard';
import { samlAuthGuard } from '../../core/guards/saml.guard';

export const authRoutes: Routes = [
    {
        path: 'login',
        canActivate: [samlAuthGuard],
        loadComponent: () => import('./login/login.component').then((c) => c.LoginComponent),
        title: $localize`:@@12aa10:Login with your Finboot account`,
    },
    {
        path: 'consent',
        loadComponent: () => import('./consent/consent.component').then((c) => c.ConsentComponent),
        title: $localize`:@@45b6b0:Grant access to you account`,
    },
    {
        path: 'signup',
        loadComponent: () => import('./signup/signup.component').then((c) => c.SignupComponent),
        title: $localize`:@@3012bc:Setup your Finboot account`,
    },
    {
        path: 'signin',
        canActivate: [samlLoginGuard(), loggedOutGuard()],
        loadComponent: () => import('./signin/signin.component').then((c) => c.SigninComponent),
        title: $localize`:@@12aa10:Login with your Finboot account`,
    },
    {
        path: 'forgot-password',
        canActivate: [loggedOutGuard()],
        loadComponent: () =>
            import('./forgot-password/forgot-password.component').then(
                (c) => c.ForgotPasswordComponent,
            ),
        title: $localize`:@@ad80ce:Password Recovery`,
    },
    {
        path: 'restore-password-response',
        canActivate: [loggedOutGuard()],
        loadComponent: () =>
            import('./change-password/change-password.component').then(
                (c) => c.ChangePasswordComponent,
            ),
        title: $localize`:@@bf5447:Change your Password`,
    },
    {
        path: 'logout',
        canActivate: [loggedInGuard()],
        loadComponent: () => import('./logout/logout.component').then((c) => c.LogoutComponent),
    },
    { path: '**', redirectTo: 'signin' },
];
